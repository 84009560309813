<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-row>
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="cluster_name">Filter By Cluster</label>
                <v-select
                  id="cluster_name"
                  v-model="filter.cluster"
                  :options="filterClusters"
                  type="text"
                  label="cluster_name"
                  :disabled="state.busy || state.fetching.clusters || state.regionProvinceMunicipality"
                  :loading="state.fetching.clusters"
                  @option:selected="onClusterFilterChange"
                >
                  <template #option="{ cluster_name }">
                    <div class="d-flex flex-column">
                      <span>{{ cluster_name }}</span>
                    </div>
                  </template>
                  <template #no-options="">
                    No Available Cluster
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="area_name">Filter By Area</label>
                <v-select
                  id="area_name"
                  v-model="filter.area"
                  :options="filterAreas"
                  type="text"
                  label="area_name"
                  :disabled="state.busy || state.fetching.areas || state.regionProvinceMunicipality"
                  :loading="state.fetching.areas"
                  @option:selected="onTableRefresh"
                >
                  <template #option="{ area_name }">
                    <div class="d-flex flex-column">
                      <span>{{ area_name }}</span>
                    </div>
                  </template>
                  <template #no-options="">
                    No Available Area
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          cols="12"
        >
          <b-row>
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="region_name">Filter By Region</label>
                <v-select
                  id="region_name"
                  v-model="filter.region"
                  :options="filterRegions"
                  type="text"
                  label="region_name"
                  :disabled="state.busy || state.fetching.regions || state.clusterArea"
                  :loading="state.fetching.regions"
                  @option:selected="onRegionFilterChange"
                >
                  <template #option="{ region_name }">
                    <div class="d-flex flex-column">
                      <span>{{ region_name }}</span>
                    </div>
                  </template>
                  <template #no-options="">
                    No Available Province
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="province_name">Filter By Province</label>
                <v-select
                  id="province_name"
                  v-model="filter.province"
                  :options="filterProvinces"
                  type="text"
                  label="province_name"
                  :disabled="state.busy || state.fetching.provinces || state.clusterArea"
                  :loading="state.fetching.provinces"
                  @option:selected="onProvinceFilterChange"
                >
                  <template #option="{ province_name }">
                    <div class="d-flex flex-column">
                      <span>{{ province_name }}</span>
                    </div>
                  </template>
                  <template #no-options="">
                    No Available Province
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="municipality_name">Filter By Municipality</label>
                <v-select
                  id="municipality_name"
                  v-model="filter.municipality"
                  :options="filterMunicipalities"
                  type="text"
                  label="municipality_name"
                  :disabled="state.busy || state.fetching.municipalities || state.clusterArea"
                  :loading="state.fetching.municipalities"
                  @option:selected="onTableRefresh"
                >
                  <template #option="{ municipality_name }">
                    <div class="d-flex flex-column">
                      <span>{{ municipality_name }}</span>
                    </div>
                  </template>
                  <template #no-options="">
                    No Available Province
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <json-excel
            class="btn btn-outline-success btn-sm"
            :fetch="myExporter"
            :before-generate="startExport"
            :before-finish="finishExport"
            :disabled="state.exporting || tableSettings.totalRows < 1"
            :fields="exportAs.excel.fields"
            :escape-csv="exportAs.excel.settings.escape"
            :type="exportAs.excel.settings.type"
            :name="excelFileName"
            :header="excelFileHeaderName"
          >
            <span
              v-if="state.exporting"
              class="align-middle"
            >Exporting <i class="ri-loader-2-line" /></span>
            <span
              v-else
              class="align-middle"
            >Export as Excel</span>
          </json-excel>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AdminReportService, SharedListService } from '@/services'
import JsonExcel from 'vue-json-excel'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminExportByGroup',

  middleware: ['auth', 'admin'],

  components: {
    JsonExcel
  },

  mixins: [formatter],

  metaInfo () {
    return {
      title: 'Export By Group'
    }
  },

  data () {
    return {
      state: {
        clusterArea: false,
        regionProvinceMunicipality: false,
        busy: false,
        exporting: false,
        fetching: {
          clusters: false,
          areas: false,
          regions: false,
          provinces: false,
          municipalities: false
        }
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now()),
        cluster: null,
        area: null,
        region: null,
        province: null,
        municipality: null
      },
      list: {
        clusters: [],
        areas: [],
        regions: [],
        provinces: [],
        municipalities: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          'first_name',
          'last_name',
          'mobile_number',
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      exportAs: {
        excel: {
          settings: {
            type: 'xls',
            escape: false
          },
          fields: {
            'Last Name': 'last_name',
            'Firt Name': 'first_name',
            'Mobile Number': 'mobile_number',
            Cluster: 'area.cluster.cluster_name',
            Area: 'area.area_name',
            Region: 'municipality.province.region.region_name',
            Province: 'municipality.province.province_name',
            Municipality: 'municipality.municipality_name',
            Barangay: 'barangay.barangay_name',
            Gender: 'gender'
          }
        }
      }
    }
  },

  computed: {

    filterClusters () {
      return [{ id: 'All', cluster_name: 'All' }].concat(this.list.clusters)
    },

    filterAreas () {
      return [{ id: 'All', area_name: 'All' }].concat(this.list.areas)
    },

    filterRegions () {
      return [{ id: 'All', region_name: 'All' }].concat(this.list.regions)
    },

    filterProvinces () {
      return [{ id: 'All', province_name: 'All' }].concat(this.list.provinces)
    },

    filterMunicipalities () {
      return [{ id: 'All', municipality_name: 'All' }].concat(this.list.municipalities)
    },

    excelFileName () {
      if (this.state.clusterArea) {
        return `Farmer Details By - ${this.filter.cluster?.cluster_name}-${this.filter.area?.area_name}.xls`
      } else if (this.state.regionProvinceMunicipality) {
        return `Farmer Details By - ${this.filter.region?.region_name}-${this.filter.province?.province_name}-${this.filter.municipality?.municipality_name}.xls`
      } else {
        return 'Farmer Details - All.xls'
      }
    },

    excelFileHeaderName () {
      const over = `( Page ${this.tableSettings.currentPage} of ${this.tableSettings.perPage} out of ${this.tableSettings.totalRows} )`

      if (this.state.clusterArea) {
        return `Farmer Details ${over} ( Cluster and Area ) ( ${this.filter.cluster?.cluster_name} - ${this.filter.area?.area_name} )`
      } else if (this.state.regionProvinceMunicipality) {
        return `Farmer Details ${over} ( Region, Province and Municipality ) ( ${this.filter.region?.region_name} - ${this.filter.province?.province_name} - ${this.filter.municipality?.municipality_name} )`
      } else {
        return `All Farmer Details ${over}`
      }
    }

  },

  watch: {

    'filter.cluster' (current) {
      if (current === null) {
        this.state.clusterArea = false
      } else {
        this.state.clusterArea = true
      }

      this.list.areas = []
      this.filter.area = null
    },

    'filter.region' (current) {
      if (current === null) {
        this.state.regionProvinceMunicipality = false
      } else {
        this.state.regionProvinceMunicipality = true
      }

      this.list.provinces = []
      this.list.municipalities = []

      this.filter.province = null
      this.filter.municipality = null
    },

    'filter.province' (current) {
      this.list.municipalities = []
      this.filter.municipality = null
    }

  },

  mounted () {
    core.index()
    this.getLists()
  },

  methods: {

    async myProvider (ctx) {
      try {
        const { data } = await AdminReportService.getFarmersByPlaces(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_cluster: this.filter.cluster?.id,
            filter_area: this.filter.area?.id,
            filter_region: this.filter.region?.id,
            filter_province: this.filter.province?.id,
            filter_municipality: this.filter.municipality?.id
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myExporter () {
      try {
        const { data } = await AdminReportService.getFarmersByPlaces(
          this.objectToUrl({
            page: this.tableSettings.currentPage,
            per_page: this.tableSettings.perPage,
            sort: this.tableSettings.sortBy,
            sort_desc: this.tableSettings.sortDesc,
            filter_text: this.tableSettings.filter,
            filter_cluster: this.filter.cluster?.id,
            filter_area: this.filter.area?.id,
            filter_region: this.filter.region?.id,
            filter_province: this.filter.province?.id,
            filter_municipality: this.filter.municipality?.id
          })
        )
        return data.items
      } catch {
        return []
      }
    },

    startExport () {
      this.state.exporting = true
    },

    finishExport () {
      this.state.exporting = false
    },

    async getLists () {
      this.state.fetching.clusters = true
      await SharedListService.getClusters().then(({ data }) => {
        this.list.clusters = data.map(({ id, cluster_name }) => ({
          id: id,
          cluster_name: cluster_name
        }))
        this.state.fetching.clusters = false
      })

      this.state.fetching.regions = true
      await SharedListService.getRegions().then(({ data }) => {
        this.list.regions = data.map(({ id, region_name }) => ({
          id: id,
          region_name: region_name
        }))
        this.state.fetching.regions = false
      })
    },

    async onClusterFilterChange () {
      this.state.fetching.areas = true
      await SharedListService.getAreas(`cluster_id=${this.filter.cluster.id}`).then(({ data }) => {
        this.list.areas = data.map(({ id, area_name }) => ({
          id: id,
          area_name: area_name
        }))
        this.state.fetching.areas = false
      })
    },

    async onRegionFilterChange () {
      this.state.fetching.provinces = true
      await SharedListService.getProvinces(`region_id=${this.filter.region.id}`).then(({ data }) => {
        this.list.provinces = data.map(({ id, province_name }) => ({
          id: id,
          province_name: province_name
        }))
        this.state.fetching.provinces = false
      })
    },

    async onProvinceFilterChange () {
      this.state.fetching.municipalities = true
      await SharedListService.getMunicipalities(`province_id=${this.filter.province.id}`).then(({ data }) => {
        this.list.municipalities = data.map(({ id, municipality_name }) => ({
          id: id,
          municipality_name: municipality_name
        }))
        this.state.fetching.municipalities = false
      })
    },

    onTableRefresh () {
      this.$refs.table.refresh()
    }
  }
}
</script>
